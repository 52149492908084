<template>
  <div class="content-provider-detail-oc global-content-detail-all">
    <div class="global-content-detail" >
      <div id="iHeaderDetail" ref="iHeaderDetail">
        <header-detail-component-global :sTextDetail="sTextDetailDynamic + ':'" :sBackHref="sBackHref"
          :bWithPathOrNameRoute="bWithPathOrNameRoute" />
        <v-breadcrumbs :items="itemsBreadcrumbs" divider="/"></v-breadcrumbs>
      </div>
      <!-- #region COMPONENTS PROVIDERS -->
      <provider-general-information-global v-if="iTypeActor === 0" :iTab="iTypeActor" />
      <person-general-information-global v-if="iTypeActor === 1" :iTab="iTypeActor" />
      <!-- #endregion COMPONENTS PROVIDERS -->

      <!-- #region COMPONENTS CUSTOMERS -->
      <customer-general-information-global v-if="iTypeActor === 2" :iTab="iTypeActor" :sIdCustomer="sIdCustomer"
        :bAdminCustomer="false" />
      <!-- #endregion COMPONENTS CUSTOMERS -->
    </div>
    <!-- <footer-component-global /> -->
  </div>
</template>

<script>

export default {
  props: {
    itemsBreadcrumbs: Array,
    sBackHref: String,
    bWithPathOrNameRoute: { type: Boolean, default: false },
  },
  data() {
    return {
      tab: 0,
      iHeaderDetail: 0,
      screenHeight: 0,
      heightMax: 0,
      iTypeActor: 0,
      itemsBreadcrumbsEdit: [],
      sTextDetailDynamic: ""
    };
  },
  beforeMount() { this.setTabToProvider() },
  updated() {
    this.matchHeight();
  },
  created() {
    window.addEventListener("resize ", this.matchHeight);
    this.matchHeight();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    this.$store.commit("setsNameDetail", "");
    window.addEventListener("resize ", this.matchHeight);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    //#region RESIZE PAGE
    matchHeight() {
      this.$nextTick((e) => {
        if (this.$refs.iHeaderDetail !== undefined) {
          this.heightMax = this.$refs.iHeaderDetail.clientHeight;
          this.heightMax = this.heightMax + 123; //123 IS NAVBAR
        }
        if (window.innerWidth > 599) {
          this.screenHeight = window.innerHeight - 100;
        } else {
          this.screenHeight = window.innerHeight - 100;
        }
      });
    },
    handleResize: function () {
      this.matchHeight();
    },
    //#endregion RESIZE PAGE
    setTabToProvider() {
      this.iTypeActor = this.getTypeActor();
      this.$store.commit("setTabPosition", { sTabPosition: this.iTypeActor });

    },
    getTypeActor() {
      this.itemsBreadcrumbsEdit = this.itemsBreadcrumbs;
      switch (this.sTypeActor.toLowerCase()) {
        case "enterprise":
          this.sTextDetailDynamic = "Detalle de una empresa"
          this.itemsBreadcrumbsEdit[2].text = this.sTextDetailDynamic
          return 0;
        case "person":
          this.sTextDetailDynamic = "Detalle una persona física"
          this.itemsBreadcrumbsEdit[2].text = this.sTextDetailDynamic
          return 1;
        case "customer":
          this.sTextDetailDynamic = "Detalle un cliente"
          this.itemsBreadcrumbsEdit[2].text = this.sTextDetailDynamic
          return 2;

        default:
          return null
      }
    }

  },
  computed: {
    sTypeActor() {
      return this.$store.state.sTypeActor;
    },
    sIdCustomer() {
      return this.$route.params.id;
    },
  }
};
</script>

<style scoped>
</style>